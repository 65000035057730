/* src/index.css */
@font-face {
  font-family: 'MoreSugar-Regular';
  src: url('../public/MoreSugar-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MoreSugar-Thin';
  src: url('../public/MoreSugar-Thin.ttf') format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f0e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* styles.css */
.hover-animation {
  transition: all 350ms ease-in-out;
}

.hover-animation:hover {
  text-decoration: underline;
  color: #b25e5a;
  transform: scale(1.05);
}

.word {
  perspective: 1000px;
}

.word span {
  display: inline-block;
  user-select: none;
  line-height: 0.8;
}

@keyframes balance {
  0%, 100% { transform: rotate(0deg); }
  30%, 60% { transform: rotate(-45deg); }
}

@keyframes shrinkjump {
  10%, 35% { transform: scale(2, .2) translate(0, 0); }
  45%, 50% { transform: scale(1) translate(0, -150px); }
  80% { transform: scale(1) translate(0, 0); }
}

@keyframes falling {
  12% { transform: rotateX(240deg); }
  24% { transform: rotateX(150deg); }
  36% { transform: rotateX(200deg); }
  48% { transform: rotateX(175deg); }
  60%, 85% { transform: rotateX(180deg); }
  100% { transform: rotateX(0deg); }
}

@keyframes rotate {
  20%, 80% { transform: rotateY(180deg); }
  100% { transform: rotateY(360deg); }
}

@keyframes toplong {
  10%, 40% { transform: translateY(-48vh) scaleY(1); }
  90% { transform: translateY(-48vh) scaleY(4); }
}

.word span.active:nth-child(1) { animation: balance 1.5s ease-out; }
.word span.active:nth-child(2) { animation: shrinkjump 1s ease-in-out; }
.word span.active:nth-child(3) { animation: falling 2s ease-out; }
.word span.active:nth-child(4) { animation: rotate 1s ease-out; }
.word span.active:nth-child(5) { animation: toplong 1.5s linear; }
.word span.active:nth-child(6) { animation: balance 1.5s ease-out; }
.word span.active:nth-child(7) { animation: shrinkjump 1s ease-in-out; }
.word span.active:nth-child(8) { animation: falling 2s ease-out; }
.word span.active:nth-child(9) { animation: rotate 1s ease-out; }

/* Hides the element when the screen is in landscape mode on small devices (phones) */
@media only screen and (max-height: 450px) and (orientation: landscape) {
  .hide-on-landscape {
    display: none;
  }
  .padding-on-landscape {
    padding-top: 2rem;
  }
}
